// src/component/cookiewidget/index.tsx

'use client';
import React, { useEffect } from 'react';
import * as CookieConsent from 'vanilla-cookieconsent';
import pluginConfig from './CookieConsentConfig';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

const CookieConsentComponent = () => {
  useEffect(() => {
    CookieConsent.run({
      ...pluginConfig,
      onFirstConsent: ({ acceptedCategories }: any) => {
        handleConsentChange(acceptedCategories);
      },
      onChange: ({ acceptedCategories }: any) => {
        handleConsentChange(acceptedCategories);
      },
    });
  }, []);

  const handleConsentChange = (acceptedCategories: any) => {
    if (acceptedCategories.includes('analytics')) {
      loadAnalytics();
    } else {
      // Jeśli użytkownik nie wyraził zgody na analitykę, możemy usunąć istniejące skrypty i wyczyścić dane
      removeAnalytics();
    }
  };

  const loadAnalytics = () => {
    // Dodaj skrypt Google Analytics
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-WFQQT5SC78';
    script.async = true;
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.id = 'google-analytics';
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-WFQQT5SC78', {
        'anonymize_ip': true,
        'storage': 'none',
        'client_storage': 'none',
        'allow_google_signals': false,
        'allow_ad_personalization_signals': false
      });
    `;
    document.head.appendChild(script2);
  };

  const removeAnalytics = () => {
    // Usuń skrypty Google Analytics
    const script = document.querySelector('script[src*="gtag/js"]');
    const script2 = document.getElementById('google-analytics');

    if (script) script.remove();
    if (script2) script2.remove();

    // Wyczyść dane analityczne (opcjonalnie)
    window['ga-disable-G-WFQQT5SC78'] = true;
  };

  return (
    <button
      type="button"
      className="px-2 bg-black text-white font-bold text-lg"
      onClick={CookieConsent.showPreferences}
    >
      Sprawdź preferencje Plików Cookie
    </button>
  );
};

export default CookieConsentComponent;
